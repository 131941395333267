<template>
    <div class="our-history">
        <section id="historyBanner">
            <our-history-banner></our-history-banner>
        </section>
        <section id="ourFounders">
            <our-founders></our-founders>
        </section>
        <section id="AwardsAndRecognition">
            <awards-and-recognitions></awards-and-recognitions>
        </section>
        <section id="ImpactMap">
            <impact-map></impact-map>
        </section>
        <section id="PartnerSection">
            <partners-section></partners-section>
        </section>
        <!--        <founder-details-page></founder-details-page>-->
    </div>
</template>

<script>
import OurHistoryBanner from './components/OurHistory/OurHistoryBanner.vue';
import OurFounders from '@/views/components/OurHistory/OurFounders';
import AwardsAndRecognitions from '@/views/components/OurHistory/AwardsAndRecognitions';
import ImpactMap from '@/views/components/OurHistory/ImpactMap';
import PartnersSection from '@/views/components/OurHistory/PartnersSection';
// import FounderDetailsPage from '@/views/FounderDetailsPage';
export default {
    name: 'OurHistoryPage',
    components: {
        // FounderDetailsPage,
        PartnersSection,
        ImpactMap,
        AwardsAndRecognitions,
        OurFounders,
        OurHistoryBanner
    },
    beforeRouteEnter (to, from, next) {
        console.log('beforeRouteEnter', from, String(from.fullPath).includes('/founder-info/'));
        if (String(from.fullPath).includes('/founder-info/')) {
            console.log('prev Route is founder details');
            const ourFoundersEl = document.querySelector('#our-founders-s');
            console.log('our founder El', ourFoundersEl);
        }
        next();
    }
    // mounted () {
    //     console.log('prev link', this.$router.getPrevLink());
    // }
};
</script>

<style>

</style>
