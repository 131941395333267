<template>
    <div class="py-5">
        <div class="container text-center">
            <div class="card">
                <h2 class="mb-5">Partner In Project</h2>
                <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">
                    <div class="row ext-center">
                        <div class="col-12 col-lg-4">
                            <validated-input type="text" placeholder="Enter Name" v-model="model.name" :disabled="loading" :border-radius="0"></validated-input>
                        </div>
                        <div class="col-12 col-lg-4">
                            <validated-input type="text" placeholder="Organization"
                                             :border-radius="0" v-model="model.organization" :disabled="loading"></validated-input>
                        </div>
                        <div class="col-12 col-lg-4">
                            <validated-input type="text" placeholder="Designation"
                                             :border-radius="0" v-model="model.designation" :disabled="loading"></validated-input>
                        </div>
                        <div class="col-12 col-lg-4">
                            <validated-input type="email" placeholder="Email ID"
                                             :border-radius="0" v-model="model.email" :disabled="loading"></validated-input>
                        </div>
                        <div class="col-12 col-lg-4">
                            <validated-input type="phone" placeholder="Phone Number"
                                             :border-radius="0" v-model="model.phone_number" :disabled="loading"></validated-input>
                        </div>
                        <div class="col-12 col-lg-4">
                            <validated-input type="text" placeholder="Area of Interest"
                                             :border-radius="0" v-model="model.area_of_interest" :disabled="loading"></validated-input>
                        </div>
                        <div class="col-12">
                            <validated-text-area class="text-area-md" placeholder="Enter Message"
                                                 :border-radius="0" v-model="model.message" :disabled="loading"></validated-text-area>
                        </div>
                        <div class="text-center fl-x fl-j-c w-100">
                            <btn border-radius="0" type="submit" text="Apply Now" :loading="loading" loading-text="Saving..."
                                 class="py-3 px-5 lego-btn btn-basic btn-color-primary bs-1 bs-1-h red-l-gradient d-inline-block">
                            </btn>
                        </div>
                    </div>
                </b-form>
            </div>
        </div>
    </div>
</template>

<script>
import urls from '@/data/urls';

export default {
    name: 'PartnerInProject',

    data () {
        return {
            model: {
                name: '',
                organization: '',
                designation: '',
                email: '',
                phone_number: '',
                area_of_interest: '',
                message: ''
            },
            addUrl: urls.admin.partnerWithUs.add
        };
    },

    methods: {
        formSuccess () {
            alert('Success');
            location.reload();
        }
    }
};
</script>

<style scoped>

</style>
