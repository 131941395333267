<template>
    <section class="bg-1 py-7" id="#our-founders-s">
        <img src="../../../assets/img/layers/layer-06.png" alt="#" class="layout-bg left h-lg-50">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="text-center font-weight-bold mb-5">Our Founders</h2>
                </div>
                <person-info-card type="founder" class="col-12 col-lg-4 fl-x-cc" :class="{'mb-4':i< members.length - members.length % 3}"
                                  v-for="(m, i) in members" :key="i"
                                  :name="m.name" :img="m.img" :designation="m.desig" >
                    <template #details>
                        <p class="text-center  p-3 font-weight-light">
                            {{ m.info }}
                        </p>
                    </template>
                    <template #footer>
                        <router-link :to="{path:'/founder-info/' + m.name.replace(/\s+/g, '')}" class="text-primary text-decoration-none font-weight-semibold">Read More</router-link>
                    </template>
                </person-info-card>
            </div>
        </div>
    </section>
</template>

<script>
import PersonInfoCard from '@/views/components/UI/PersonInfoCard';
// import personImg1 from '../../../assets/img/board/04.jpg';
// import placeholderImg from '../../../assets/img/placeholder/placeholder.png';
import founderImg1 from '../../../assets/img/founders/01.jpg';
import founderImg2 from '../../../assets/img/founders/02.jpg';
import founderImg3 from '../../../assets/img/founders/03.jpg';

export default {
    name: 'OurFounders',
    components: { PersonInfoCard },
    data () {
        return {
            members: [
                {
                    name: 'Mr. Paul Thomas',
                    desig: 'Founder- ESAF Group of Social Enterprises',
                    img: founderImg1,
                    info: 'K. Paul Thomas is the Founder of the ESAF Group of Social Enterprises, which includes the ESAF Society and ESAF Small Finance Bank. He founded ESAF as a non-profit organisation in 1992 by organizing neighbourhood women into self-help groups (SHGs).  '
                },
                {
                    name: 'Mrs. Mereena Paul',
                    desig: 'Director',
                    img: founderImg3,
                    info: ' Mereena Paul is a renowned social entrepreneur and co-founder of ESAF.\n' +
                        '\n' +
                        ' She is the Chairperson of AMCOS (Association of Multistate Cooperative Societies in Kerala) and serves on the board of various organisations and associations.'
                },
                {
                    name: 'Dr. Jacob Samuel',
                    desig: 'Director',
                    img: founderImg2,
                    info: 'Dr Jacob Samuel is the co-founder of ESAF. He started his career in the medical field and transitioned to the development sector after cofounding ESAF. He has acted as a mentor to Paul Thomas and continues to provide thought leadership to the organisation.'
                }
            ]
        };
    }
};
</script>

<style scoped lang="scss">
section {
    position: relative;
}
.h-lg-50{
    height: 40%;
    @media (min-width: 992px) {
        height: 50%;
    }
}
</style>
