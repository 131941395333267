<template>
    <section class="py-5 bg-donate-main h-100vh fl-x-cc">
        <img src="../assets/img/layers/layer-01.png" alt="" class="layout-bg-flow flip-img">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 mb-4 mb-lg-0">
                    <div class="bg-1 p-4 p-lg-5 fl-y">
                        <div class="mb-5">
                            <h2 class="font-weight-bold text-center mb-3">Donate to ESAF Global Canada</h2>
<!--                            <div class="fl-x fl-j-c">-->
<!--                                &lt;!&ndash;                                <label class="xeo-checkbox-1 mr-3">Give once&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    <input type="checkbox" checked="checked"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                           value="Digital Marketing" name="c5">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    <span class="checkmark"></span>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                </label>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                <label class="xeo-checkbox-1">Monthly&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    <input type="checkbox" checked="checked"&ndash;&gt;-->
<!--                                &lt;!&ndash;                                           value="Digital Marketing" name="c5">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    <span class="checkmark"></span>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                </label>&ndash;&gt;-->
<!--                                <div class="c-tab-btn py-3 mr-2" :class="{active:giveOnce}" @click="setPayment('once')">-->
<!--                                    Give-->
<!--                                    Once-->
<!--                                </div>-->
<!--                                <div class="c-tab-btn py-3" :class="{active: monthly}" @click="setPayment('monthly')">-->
<!--                                    Monthly-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <transition name="fade" mode="out-in">-->
<!--                                <div v-if="giveOnce" class="fl-y fl-a-c" key="once">-->
<!--                                    <div class="row ">-->
<!--                                        <table class="w-100 donate-table d-none d-lg-block">-->
<!--                                            <tr>-->
<!--                                                <td>-->
<!--                                                    <div class="box-donate">-->
<!--                                                        $ <span class="fs-5 font-weight-semibold">50</span> USD-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                                <td>-->
<!--                                                    <div class="box-donate">-->
<!--                                                        $ <span class="fs-5 font-weight-semibold">50</span> USD-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                                <td>-->
<!--                                                    <div class="box-donate">-->
<!--                                                        $ <span class="fs-5 font-weight-semibold">50</span> USD-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                            </tr>-->
<!--                                            <tr>-->
<!--                                                <td>-->
<!--                                                    <div class="box-donate">-->
<!--                                                        $ <span class="fs-5 font-weight-semibold">50</span> USD-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                                <td colspan="2">-->
<!--                                                    <div class="box-donate">-->
<!--                                                        &lt;!&ndash; Custom Amount &ndash;&gt;-->
<!--                                                        <validated-input type="number"-->
<!--                                                                         class="form-group-m-0 px-3 form-label-bold form-input-h-unset"-->
<!--                                                                         label="Custom Amount"></validated-input>-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                            </tr>-->
<!--                                        </table>-->
<!--                                        <table class="w-100 donate-table d-lg-none">-->
<!--                                            <tr>-->
<!--                                                <td>-->
<!--                                                    <div class="box-donate">-->
<!--                                                        $ <span class="fs-5 font-weight-semibold">50</span> USD-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                                <td>-->
<!--                                                    <div class="box-donate">-->
<!--                                                        $ <span class="fs-5 font-weight-semibold">50</span> USD-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                            </tr>-->
<!--                                            <tr>-->
<!--                                                <td>-->
<!--                                                    <div class="box-donate">-->
<!--                                                        $ <span class="fs-5 font-weight-semibold">50</span> USD-->
<!--                                                    </div>-->
<!--                                                </td>-->

<!--                                                <td>-->
<!--                                                    <div class="box-donate">-->
<!--                                                        $ <span class="fs-5 font-weight-semibold">50</span> USD-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                            </tr>-->
<!--                                            <tr>-->
<!--                                                <td colspan="2">-->
<!--                                                    <div class="box-donate">-->
<!--                                                        Custom Amount-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                            </tr>-->
<!--                                        </table>-->
<!--                                    </div>-->
<!--                                    <div class="fl-x">-->
<!--                                        <label class="xeo-checkbox-1 mr-3">Give in honor of someone-->
<!--                                            <input type="checkbox" checked="checked"-->
<!--                                                   value="Digital Marketing" name="c5">-->
<!--                                            <span class="checkmark"></span>-->
<!--                                        </label>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div v-else class="fl-y fl-a-c" key="monthly">-->
<!--                                    <div class="row ">-->
<!--                                        <table class="w-100 donate-table d-none d-lg-block">-->
<!--                                            <tr>-->
<!--                                                <td>-->
<!--                                                    <div class="box-donate">-->
<!--                                                        $ <span class="fs-5 font-weight-semibold">50</span> USD-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                                <td>-->
<!--                                                    <div class="box-donate">-->
<!--                                                        $ <span class="fs-5 font-weight-semibold">50</span> USD-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                                <td>-->
<!--                                                    <div class="box-donate">-->
<!--                                                        $ <span class="fs-5 font-weight-semibold">50</span> USD-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                            </tr>-->
<!--                                            <tr>-->
<!--                                                <td>-->
<!--                                                    <div class="box-donate">-->
<!--                                                        $ <span class="fs-5 font-weight-semibold">50</span> USD-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                                <td colspan="2">-->
<!--                                                    <div class="box-donate">-->
<!--                                                        &lt;!&ndash; Custom Amount &ndash;&gt;-->
<!--                                                        <validated-input type="number"-->
<!--                                                                         class="form-group-m-0 px-3 form-label-bold form-input-h-unset"-->
<!--                                                                         label="Custom Amount"></validated-input>-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                            </tr>-->
<!--                                        </table>-->
<!--                                        <table class="w-100 donate-table d-lg-none">-->
<!--                                            <tr>-->
<!--                                                <td>-->
<!--                                                    <div class="box-donate">-->
<!--                                                        $ <span class="fs-5 font-weight-semibold">50</span> USD-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                                <td>-->
<!--                                                    <div class="box-donate">-->
<!--                                                        $ <span class="fs-5 font-weight-semibold">50</span> USD-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                            </tr>-->
<!--                                            <tr>-->
<!--                                                <td>-->
<!--                                                    <div class="box-donate">-->
<!--                                                        $ <span class="fs-5 font-weight-semibold">50</span> USD-->
<!--                                                    </div>-->
<!--                                                </td>-->

<!--                                                <td>-->
<!--                                                    <div class="box-donate">-->
<!--                                                        $ <span class="fs-5 font-weight-semibold">50</span> USD-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                            </tr>-->
<!--                                            <tr>-->
<!--                                                <td colspan="2">-->
<!--                                                    <div class="box-donate">-->
<!--                                                        Custom Amount-->
<!--                                                    </div>-->
<!--                                                </td>-->
<!--                                            </tr>-->
<!--                                        </table>-->
<!--                                    </div>-->
<!--                                    <div class="fl-x">-->
<!--                                        <label class="xeo-checkbox-1 mr-3">Give in honor of someone-->
<!--                                            <input type="checkbox" checked="checked"-->
<!--                                                   value="Digital Marketing" name="c5">-->
<!--                                            <span class="checkmark"></span>-->
<!--                                        </label>-->
<!--                                    </div>-->
<!--                                </div>-->

<!--                            </transition>-->

                        </div>
                        <div class="text-center mt-auto">
<!--                            <btn text="Donate" @click="openDonateToProjectModal('Donate to a Project')"-->
<!--                                 class="py-3 px-5" design="basic-b"-->
<!--                                 border-radius="0"/>-->
                            <a class="xeo-btn" target="_blank" href="https://www.canadahelps.org/CharityProfilePage.aspx?CharityID=d119640">
                                <img src="https://www.canadahelps.org/secure/img/Donate1_en.png" border="0"
                                     alt="Donate Now Through CanadaHelps.org!"/></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 fl-x-cc">
                    <div class="text-white">
                        <div class="fl-x fl-a-c works-img-cont" @click="donateShow('tab1')">
                            <div class="bg-white img-contain mr-3 fl-x fl-a-c fl-j-c p-3">
                                <div class="">
                                    <img src="../assets/img/donate/icon-1.png" alt="" class="img-fluid">
                                </div>
                            </div>
                            <h2 class="bottom-bar text-left header-res-text text-white">Skill Training & <br
                                class="m-0">
                                Livelihood
                            </h2>
                        </div>
                        <transition name="fade" mode="out-in">
                            <div class="" v-if="tab1">
                                <ul class="donate-list">
                                    <li @click="openAdoptProjectModal('Our Skill Development & Livelihood Project',
                                    'Skill Development')" class="cursor-ponter">Our Skill Development
                                        & Livelihood Projects
                                    </li>
                                    <!--                                    <li @click="openAdoptProjectModal('Adopt a Mobile Training Center',-->
                                    <!--                                    'Skill Development')" class="cursor-ponter">Adopt a Mobile Training-->
                                    <!--                                        Center-->
                                    <!--                                    </li>-->
                                    <li @click="openDonateToProjectModal('Donate to ESAF Global Canada',
                                    'Skill Development')" class="cursor-ponter">
                                        Donate to General Skill Development & Livelihood Project
                                    </li>
                                </ul>
                            </div>
                        </transition>
                        <div class="fl-x fl-a-c works-img-cont" @click="donateShow('tab2')">
                            <div class="bg-white img-contain mr-3 fl-x fl-a-c fl-j-c p-3">
                                <div class="">
                                    <img src="../assets/img/donate/icon-2.png" alt="" class="img-fluid">
                                </div>
                            </div>
                            <h2 class="bottom-bar header-res-text text-white">Education</h2>
                        </div>
                        <transition name="fade" mode="out-in">
                            <div class="" v-if="tab2">
                                <ul class="donate-list">
                                    <!--                                    <li @click="openAdoptProjectModal('Adopt an Education Project', 'Education')"-->
                                    <!--                                        class="cursor-ponter">Adopt an Education-->
                                    <!--                                        Project-->
                                    <!--                                    </li>-->
                                    <li @click="openAdoptProjectModal('Our Mobile School', 'Education')"
                                        class="cursor-ponter">Our Mobile School
                                    </li>
                                    <li @click="openDonateToProjectModal('Donate to ESAF Global Canada', 'Education')"
                                        class="cursor-ponter">Donate to General
                                        Education Project
                                    </li>
                                </ul>
                            </div>
                        </transition>
                        <div class="fl-x fl-a-c works-img-cont" @click="donateShow('tab3')">
                            <div class="bg-white img-contain mr-3 fl-x fl-a-c fl-j-c">
                                <div class="">
                                    <img src="../assets/img/donate/icon-3.png" alt="" class="img-fluid">
                                </div>
                            </div>
                            <h2 class="bottom-bar header-res-text text-white">Healthcare</h2>
                        </div>
                        <transition name="fade" mode="out-in">
                            <div class="" v-if="tab3">
                                <ul class="donate-list">
                                    <!--                                    <li @click="openAdoptProjectModal('Adopt a HealthCare Project', 'Healthcare')"-->
                                    <!--                                        class="cursor-ponter">Adopt a HealthCare Project-->
                                    <!--                                    </li>-->
                                    <li @click="openAdoptProjectModal('Our Mobile Clinic', 'Healthcare')"
                                        class="cursor-ponter">Our Mobile Clinic
                                    </li>
                                    <li @click="openDonateToProjectModal('Donate to ESAF Global Canada', 'Healthcare')"
                                        class="cursor-ponter">Donate to General
                                        HealthCare Project
                                    </li>
                                </ul>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
        <!--        <btn text="Donate to Project" @click="openDonateToProjectModal"></btn>-->
        <!--        <btn text="Adopt a Project" @click="openAdoptProjectModal"></btn>-->
        <modal ref="donateToProject" class="c-modal" @next-modal="alert('next-modal')"
               title="Donate to Project">
            <donate-to-project-form :title-name="title" :type="type"
                                    @close="$refs.donateToProject.close();"></donate-to-project-form>
        </modal>
        <modal ref="adoptProject" class="c-modal"
               title="Donate to Project">
            <adopt-a-project :title-name="title" :type="type" @close="$refs.adoptProject.close();"></adopt-a-project>
        </modal>

    </section>
</template>

<script>
import DonateToProjectForm from '@/views/components/Donate/DonateToProjectForm';
import AdoptAProject from '@/views/components/Donate/AdoptAProject';
// import PaymentModal from "@/views/components/Donate/PaymentModal";

export default {
    name: 'Donate',
    components: { AdoptAProject, DonateToProjectForm },
    data () {
        return {
            tab1: false,
            tab2: false,
            tab3: false,
            title: '',
            type: '',
            giveOnce: true,
            monthly: false
        };
    },
    methods: {
        donateShow (item) {
            if (item === 'tab1') {
                this.tab1 = !this.tab1;
                this.tab2 = false;
                this.tab3 = false;
            }
            if (item === 'tab2') {
                this.tab2 = !this.tab2;
                this.tab1 = false;
                this.tab3 = false;
            }
            if (item === 'tab3') {
                this.tab3 = !this.tab3;
                this.tab2 = false;
                this.tab1 = false;
            }
        },
        openDonateToProjectModal (name, type) {
            this.title = name;
            this.type = type;
            this.$refs.donateToProject.show();
        },
        openAdoptProjectModal (name, type) {
            this.title = name;
            this.type = type;
            this.$refs.adoptProject.show();
        },
        toNextModal () {
            // alert('next modal');
            this.$refs.donateToProject.close();
            this.$refs.adoptProject.show();
        },
        setPayment (opt) {
            if (opt === 'once') {
                this.giveOnce = true;
                this.monthly = false;
            } else {
                this.giveOnce = false;
                this.monthly = true;
            }
        }
    }
};
</script>

<style scoped lang="scss">

.bg-donate-main {
    background: url('../assets/img/bg/bg-11.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    .donate-table {
        border-spacing: 15px;
    }

    .box-donate {
        min-height: 77px;
        background-color: #fff;
        border: 1px solid var(--color-primary);
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
            background-color: #dedede;
        }
    }
}

.layout-bg-flow {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
    @media (min-width: 992px) {
        width: 20%;
    }
}

.flip-img {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.img-contain {
    z-index: 2;
    width: 8.75rem;
    height: auto;
}

.works-img-cont {
    margin-bottom: 15px;

    img {
        z-index: 1;
    }

}

.donate-list {
    list-style: none;
    transition: 0.3s;

    li {
        margin-bottom: 15px;
        position: relative;
        padding-left: 30px;

        &:before {
            content: "";
            position: absolute;
            left: 5px;
            top: 3px;
            width: 15px;
            height: 15px;
            background: url('../assets/img/layers/layer-09.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

.c-tab-btn {
    text-decoration: none;
    //background-color: #f2f6fa;
    //background-color: #efc4c2;
    transition: all 500ms ease-in-out;
    width: 40%;
    text-align: center;
    border-radius: .7rem;
    font-size: 1.1rem;

    &:hover {
        //background-color: #ccdcea;
        background-color: var(--color-primary);
        //background-color: var(--color-primary-400);
        //background-color: #bd5752;
        color: #fafafa;

        cursor: pointer;
    }

    &.active {
        //background-color: #ccdcea;
        background-color: var(--color-primary);
        //background-color: var(--color-primary-400);
        //background-color: #bd5752;
        color: #fafafa;
    }
}
</style>
