<template>
    <div class="bannner-h bg-contact-banner">
        <div class="container py-5">
            <div class="row">
                <div class="col-12 col-lg-6 order-lg-1">
                    <img src="../../../assets/img/contact/Group492.png" alt="" class="img-fluid">
                </div>
                <div class="col-12 col-lg-6 order-lg-0">
                    <div class="text-black fl-y fl-a-s fl-j-c h-100">
                        <h3 class="font-weight-bold mb-4">Contact Us</h3>
                        <div class="w-40">
                            <i class="fa fa-map-marker mr-2"
                               aria-hidden="true"></i>
                            <span class="font-weight-bold">Find Us At</span>
                            <!--                            <p>-->
                            <!--                                11680 Sarcee Trail Nw Calgary-->
                            <!--                                AB, T3R 0A1, Canada-->
                            <!--                            </p>-->
                            <p>
                                PO Box 68152 Crowfoot PO Calgary AB Canada T3G 3N8
                            </p>
                        </div>
                        <div class="">
                            <i class="fa fa-phone mr-2"
                               aria-hidden="true"></i>
                            <span class="font-weight-bold">Phone</span>
                            <p>
                                +1(403)771-9301
                            </p>
                        </div>
                        <div class="">
                            <i class="fa fa-envelope mr-2"
                               aria-hidden="true"></i>
                            <span class="font-weight-bold">Email ID</span>
                            <p>
                                info@esafglobal.ca
                            </p>
                        </div>
                    </div>
                    <div class="text-black">

                        <div class="text-black">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ContactUsBanner'
};
</script>

<style scoped lang="scss">
.bannner-h {
    min-height: calc(58px - 100vh);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    background-position: right;
    line-height: 2.5rem;
    @media (min-width: 992px) {
        min-height: calc(84px - 100vh);
    }
    //@media (max-width: 1024px) {
    //    background-position: 80% 76px;
    //}
    h2 {
        font-size: 2.5rem;
        line-height: 4rem;
    }

    @media (max-width: 992px) {
        align-items: center;
        padding-bottom: 20px;

        h2 {
            font-size: 2rem;
            line-height: unset;
        }
    }

    @media (min-width: 992px) {
        .bottom-section {
            position: absolute;
            bottom: 40px;
            left: 15px;
        }
        .content {
            margin-top: -84px !important;
        }
    }

    table {
        td {
            padding-right: 15px;
        }
    }

    .cont-height {
        min-height: calc(100vh - 58px);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        @media (min-width: 992px) {
            min-height: calc(100vh - 84px);
        }
    }

}
</style>
