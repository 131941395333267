<template>
    <section class="py-7">
        <div class="container">
            <h2 class="text-center font-weight-semibold mb-3">ESAF India Partners</h2>

            <swiper class="swiper" :options="swiperOption">
                <swiper-slide v-for="(img,i) in images" :key="i">
                    <div>
                        <img :src="img" class="container-fluid img-grayscale" alt="">
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

import img1 from '../../../assets/img/partners/01.png';
import img2 from '../../../assets/img/partners/1.png';
// import img3 from '../../../assets/img/partners/02.png';
import img4 from '../../../assets/img/partners/2.png';
import img5 from '../../../assets/img/partners/03.png';
import img6 from '../../../assets/img/partners/3.png';
import img7 from '../../../assets/img/partners/4.png';
import img8 from '../../../assets/img/partners/5.png';
import img9 from '../../../assets/img/partners/6.png';
import img10 from '../../../assets/img/partners/7.png';
import img11 from '../../../assets/img/partners/8.png';
import img12 from '../../../assets/img/partners/9.png';
import img13 from '../../../assets/img/partners/10.png';
import img14 from '../../../assets/img/partners/11.png';
import img15 from '../../../assets/img/partners/12.png';
import img16 from '../../../assets/img/partners/13.png';
import img17 from '../../../assets/img/partners/14.png';
import img18 from '../../../assets/img/partners/15.png';
import img19 from '../../../assets/img/partners/16.png';
import img20 from '../../../assets/img/partners/17.png';
import img21 from '../../../assets/img/partners/18.png';
import img22 from '../../../assets/img/partners/19.png';
import img23 from '../../../assets/img/partners/20.png';
import img24 from '../../../assets/img/partners/21.png';

export default {
    name: 'PartnersSection',
    components: {
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            swiperOption: {
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false
                },
                breakpoints: {
                    1200: {
                        slidesPerView: 4
                    },
                    768: {
                        slidesPerView: 3
                    },
                    580: {
                        slidesPerView: 2
                    },
                    320: {
                        slidesPerView: 1
                    }
                }
            },
            images: [
                img1, img2, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21,
                img22, img23, img24
            ]
        };
    }
};
</script>

<style scoped lang="scss">

.img-grayscale {
    -webkit-filter: grayscale(1);
    -webkit-transition: all .2s ease-in-out;
    opacity: .5;

    &:hover {
        filter: unset;
        -webkit-filter: grayscale(0);
        opacity: 1;
    }
}
</style>
