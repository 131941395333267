<template>
    <div class="section-bg">
        <!--        <div class="container-fluid">-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-lg-4">
                    <show-card heading="Work With Us" :top-img="topImg1" class="h-100">
                        <template #content>
                            <p class="m-0">
                                Join Us Find Your Mission At ESAF Global Canada, we believe that when employees are
                                encouraged to learn and tackle new challenges, everyone benefits
                            </p>
                        </template>
                        <template #footer>
                            <router-link to="/work-with-us/"
                                         class="lego-btn px-4 round-1 bs-10 bg-white  bs-1-h btn-lg line-h-unset border-r-0 fs-1 flex-c btn-white">
                                Apply Now
                            </router-link>
                            <!--                        <btn text="Apply Now" class="px-4" size="lg" design="animated-e"></btn>-->
                        </template>
                    </show-card>
                </div>
                <div class="col-12 col-lg-4 ">
                    <show-card class="bg-cover" :bg-image="bg2" heading="Volunteer With Us" :top-img="topImg2">
                        <template #content>
                            <p>
                                Undertaking various initiatives in the field of education, health & nutrition, and child
                                protection, we are constantly on the lookout for passionate individuals driven to make a
                                difference in their lives.
                            </p>
                        </template>
                        <template #footer>
                            <router-link to="/volunteer/"
                                         class="lego-btn px-4 round-1 bs-10 bg-white  bs-1-h btn-lg line-h-unset border-r-0 fs-1 flex-c btn-white">
                                Apply Now
                            </router-link>
                            <!--                        <btn text="Apply Now" class="px-4" size="lg" design="animated-e"></btn>-->
                        </template>
                    </show-card>
                </div>
                <div class="col-12 col-lg-4">
                    <show-card heading="Partner in Project" :top-img="topImg3">
                        <template #content>
                            <p class="text-black-50">
                                Undertaking various initiatives in the field of education, health & nutrition, and child
                                protection, we are constantly on the lookout for passionate individuals or organisations
                                driven to make a difference in their lives. </p>
                        </template>
                        <template #footer>
                            <router-link to="/partner-in-project/"
                                         class="lego-btn px-4 round-1 bs-10 bg-white  bs-1-h btn-lg line-h-unset border-r-0 fs-1 flex-c btn-white">
                                Apply Now
                            </router-link>
                            <!--                        <btn text="Apply Now" class="px-4" size="lg" design="animated-e"></btn>-->
                        </template>
                    </show-card>
                </div>
            </div>
        </div>
    </div>
    <!--    </div>-->
</template>

<script>
import ShowCard from '@/views/components/UI/ShowCard';
import bg1 from '../assets/img/bg/bg-01.png';
import bg2 from '../assets/img/bg/bg-18.png';
import bg3 from '../assets/img/bg/bg-07.png';
import topImg1 from '../assets/img/work-with-us/Group508.png';
import topImg2 from '../assets/img/work-with-us/Group438.png';
import topImg3 from '../assets/img/work-with-us/Group524.png';

export default {
    name: 'WorkWithUsShowcase',
    components: { ShowCard },
    data () {
        return {
            bg1: bg1,
            bg2: bg2,
            bg3: bg3,
            topImg1: topImg1,
            topImg2: topImg2,
            topImg3: topImg3
        };
    }
};
</script>

<style scoped lang="scss">
.section-bg {
    background-image: url("../assets/img/bg/bg-07.png");
}

.flex-c {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-white {
    &:hover {
        opacity: 0.8;
    }
}

</style>
