<template>
    <section class="py-5 bg-3 pos-r full-height">
        <img src="../assets/img/layers/layer-06.png" alt="#" class="z-index-0 layout-bg left">
        <div class="container z-index-4">
            <h2 class="text-center font-weight-bold mb-3">Our Founders</h2>
            <person-info-card class="z-index-2" :name="currentFounder.name" size="lg" :img="currentFounder.img" :designation="currentFounder.design">
                <template #details>
                    <p class="text-center  p-3 font-weight-light">
                        {{ currentFounder.info }}
                    </p>
                </template>
            </person-info-card>
        </div>
    </section>
</template>

<script>
import PersonInfoCard from '@/views/components/UI/PersonInfoCard';
// import sampleImg from '@assets/img/board/0.jpg';
// import personImg1 from '@assets/img/board/04.jpg';
// import personImg2 from '@assets/img/board/06.jpg';
// import placeholderImg from '@assets/img/placeholder/placeholder.png';
import founderImg1 from '@assets/img/founders/01.jpg';
import founderImg2 from '@assets/img/founders/02.jpg';
import founderImg3 from '@assets/img/founders/03.jpg';

export default {
    name: 'FounderDetailsPage',
    components: { PersonInfoCard },
    props: {
        name: {
            type: String,
            default: 'Name'
        },
        designation: {
            type: String,
            default: 'Designation'
        },
        info: {
            type: String,
            default: 'info about the person'
        },
        img: {
            type: Image
            // default: placeholderImg
        },
        id: {
            type: Number,
            default: 0
        }
    },
    mounted () {
        // console.log('id of founder', this.$route.params.id);
        // const founderId = this.$route.params.id;
        // this.currentFounder = this.members[founderId];
        console.log('id of founder', this.$route.params.name);
        const founderName = this.$route.params.name.replace(/\s+/g, '');
        this.currentFounder = this.members.find(item => item.name.replace(/\s+/g, '') === founderName);
        console.log('current member', this.currentMember);
    },
    data () {
        return {
            currentFounder: null,
            members: [
                {
                    name: 'Mr. Paul Thomas',
                    desig: 'Director',
                    img: founderImg1,
                    info: 'K. Paul Thomas is the Founder of the ESAF Group of Social Enterprises, which includes the ESAF Society and ESAF Small Finance Bank. He founded ESAF as a non-profit organisation in 1992 by organizing neighbourhood women into self-help groups (SHGs). His contributions have been pivotal in charting the hybrid model of sustainable business by leveraging the benefits of the self-help group and joint liability group models. He is the recipient of the SKOCH Award for Financial Inclusion and Karma Ratna award for exemplary contributions to Kerala society.'
                },
                {
                    name: 'Mrs. Mereena Paul',
                    desig: 'Director',
                    img: founderImg3,
                    info: ' Mereena Paul is a renowned social entrepreneur and co-founder of ESAF.\n' +
                        '\n' +
                        ' She is the Chairperson of AMCOS (Association of Multistate Cooperative Societies in Kerala) and serves on the board of various organisations and associations.\n' +
                        '\n' +
                        'She is an expert in Human Resource Management'
                },
                {
                    name: 'Dr. Jacob Samuel',
                    desig: 'Chairman',
                    img: founderImg2,
                    info: 'Dr Jacob Samuel is the co-founder of ESAF. He started his career in the medical field and transitioned to the development sector after cofounding ESAF. He has acted as a mentor to Paul Thomas and continues to provide thought leadership to the organisation. He leads the healthcare, education and child development initiatives at ESAF. Currently, he serves as the Director of ESAF. He is an avid learner, reader and influencer.'
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
