<template>
    <div class="p-4 text-center">
        <h2 class="mb-5">{{ titleName }}</h2>
<!--        <s-form class="" @submit="onSubmit">-->
        <s-form class="">
            <div class="row text-center">
<!--                <div class="col-12 col-lg-6">-->
<!--                    <validated-ajax-vue-select placeholder="Select A Project" :url="optionURL"-->
<!--                                               :border-radius="0"></validated-ajax-vue-select>-->
<!--                </div>-->
<!--                <div class="col-12 col-lg-6">-->
<!--                    <validated-input type="number" placeholder="Custom Amount" v-model="model.amount"-->
<!--                                      :border-radius="0"></validated-input>-->
<!--                </div>-->
                <div class="text-center fl-x fl-j-c w-100">
<!--                    <btn border-radius="0" type="submit"-->
<!--                         class="py-3 px-5 lego-btn btn-basic btn-color-primary bs-1 bs-1-h red-l-gradient d-inline-block">-->
<!--                        Continue-->
<!--                    </btn>-->
                    <a class="xeo-btn" href="https://www.canadahelps.org/CharityProfilePage.aspx?CharityID=d119640">
                                <img src="https://www.canadahelps.org/secure/img/Donate1_en.png" border="0"
                                     alt="Donate Now Through CanadaHelps.org!"/></a>
                </div>
            </div>
        </s-form>
        <modal ref="paymentModal" class="c-modal"
               title="Payment">
            <payment-modal title-name="Payment" @close="$emit('close')"></payment-modal>
        </modal>
    </div>
</template>

<script>
import urls from '@/data/urls';
import PaymentModal from '@/views/components/Donate/PaymentModal';

export default {
    name: 'DonateToProjectForm',
    components: { PaymentModal },
    props: {
        titleName: { type: String },
        type: { type: String }
    },

    data () {
        return {
            optionURL: urls.admin.project.options + '?type=' + this.type,
            amountOptions: [
                { label: '$ 50', value: '$ 50' },
                { label: '$ 100', value: '$ 100' },
                { label: '$ 200', value: '$ 200' }
            ],
            model: {
                project: '',
                time_frame: '',
                name: '',
                email: '',
                phone_number: '',
                amount: ''
            }
        };
    },

    methods: {
        onSubmit () {
            // alert('Coming soon..!');
            this.openPaymentModal();
        },
        openPaymentModal () {
            this.$refs.paymentModal.show();
        }
    }
};
</script>

<style scoped>

</style>
