<template>
    <section class="py-7">
        <div class="red-overlay"></div>
        <div class="container z-index-4">
            <h2 class="text-center text-white font-weight-bold mb-3">Awards & Recognitions For ESAF India</h2>
            <p class="z-index-4 text-white" style="color: #fff">
                ESAF has gained public recognition by receiving awards from both National and International
                organizations for their accomplishments in providing innovative solutions to
                develop communities.
            </p>
            <ul class="pl-3">
                <li class="mb-4">
                    IKEA, the furniture giant, selected ESAF as one of their partners to provide craft-based furnishing
                    materials. Through this project, ESAF created thousands of jobs for
                    otherwise unemployed tribal youths and women.
                </li>
                <li class="mb-4">
                    ESAF Small Finance bank (A group institution) has won the prestigious European Microfinance Award
                    in 2018.
                </li>
                <li class="mb-4">
                    ESAF graded as NGO 2A by CARE. This grade indicates high delivery capability and high organizational
                    capability.
                </li>
                <li class="mb-4">
                    ESAF was recently empaneled by UNDP and graded as “Low risk and High sustainability “.
                </li>
                <li class="mb-4">
                    ESAF’s master Craft man selected as the best craft man by JHARCRAFT, Jharkhand government
                    initiative.
                </li>
                <li class="mb-4">
                    Walking Visionary Award 2015 by Walk 21 Vienna.
                </li>
                <li class="mb-4">
                    Trusted Partner of Development Commissioner of Handicrafts (DCH) under the Ministry of textiles for
                    last 10 years in Promotion of Traditional Craft (Bell metal,
                    wood, Screw pine)
                </li>
                <li class="mb-4">
                    ESAF microfinance is Rated mfR 4 by CRISIL which recognizes quality in the aspects of Management,
                    Institutional arrangements, Capital Adequacy and Asset Quality,
                    Resources and asset-liability management, Operational effectiveness, and Scalability and
                    sustainability.
                </li>
                <li class="mb-4">
                    Selected for implementing Sanchar Sakthi project by Ministry of Tele Communication, Government of
                    India
                </li>
                <li class="mb-4">
                    Mix Market silver category Social performance award consecutively for two years
                </li>
                <li class="mb-4">
                    ESAF Microfinance, another group institution, won the European Microfinance Award 2014 AND 2017
                    (only MFI from India) by the Government of Luxemburg

                </li>
                <li>
                    ESAF Microfinance is Ranked as the top aggregator of the National Pension Scheme of the Government
                    of India
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AwardsAndRecognitions'
};
</script>

<style scoped>
section {
    position: relative;
    background: url('../../../assets/img/bg/bg-05.png');
    background-position: top;
    color: white;
}

.red-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    /*height: 100%;*/
    height: 100%;
    background-color: rgba(128, 0, 0, 0.56);
    z-index: 0;
}
</style>
