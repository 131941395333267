<template>
    <div>
        <div class="fl-x fl-a-c mb-1">
            <img :src="img" alt="" class="mr-2 half-width" v-if="img">
            <slot name="image" v-else></slot>
            <p class="font-weight-bold" v-if="heading">{{heading}}</p>
            <slot name="heading" v-else></slot>
        </div>
        <p class="full-width w-100">{{content}}</p>
    </div>

</template>

<script>
export default {
    name: 'IconCard',
    props: {
        heading: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        },
        img: {
            type: Image,
            default: null

        }
    }
};
</script>

<style scoped>
.border-rounded {
    border-radius: 50%;
}

.bb-1-primary {
    border-bottom: 0.3em solid var(--color-primary);
}
</style>
