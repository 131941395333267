<template>
    <div>
        <section>
            <swiper class="swiper focus-banner" :options="swiperOption">
                <swiper-slide class="bg-cover w-100 h-100" :style="{'background-image':'url('+ record.img +')'}"
                              v-for="(record, index) in details.banner" v-bind:key="index">
                    <div class="overlay-dark"></div>
                    <div class="pos-r">
                        <!--                        <div class="section-overlay"></div>-->
                        <!--                        <img :src="record.img" alt="#">-->
                        <!--                        <img src="../../assets/img/programmes/Programs.jpg" alt="#">-->
                    </div>
                </swiper-slide>
            </swiper>
        </section>
        <section class="py-5 text-black">

            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="mt-3 mb-5">
                            <h2 class="text-center font-weight-bold" v-html="details.name">Our Values</h2>
                            <div class="underline-pos-abs p-left-50"></div>
                        </div>
                    </div>
                    <p class="text-justify px-3 pl-lg-0" v-html="details.content"></p>
                </div>
            </div>

        </section>

        <section class="py-5 text-black bg-focus-area pos-r">
            <div class="overlay-dark d-lg-none"></div>
            <div class="container z-index-4">
                <div class="row">
                    <div class="col-md-7 col-7 ml-auto">
                        <div class="mt-3 mb-5">
                            <h2 class="font-weight-bold text-lg-black">Focus Areas</h2>
                            <div class="underline-pos-abs p-left"></div>
                        </div>
                        <div v-for="(item, key) in details.focus_area" v-bind:key="key">
                            <div v-for="(i, k) in item" v-bind:key="k" class="focus-p-pos-rel c-padding-b-md-10">
                                <p v-html="i.area" class="c-font-15">
                                </p>
                                <div class="focus-p-pos-abs"></div>
                            </div>
                        </div>

                        <!--                        <div class="focus-slider">-->
                        <!--                            <swiper class="swiper" :options="swiperOption4">-->

                        <!--                                <swiper-slide class="" v-for="(item, key) in details.focus_area" v-bind:key="key">-->
                        <!--                                    <div class="col-12 pl-4">-->
                        <!--                                        <div class="" v-for="(i, k) in item" v-bind:key="k">-->
                        <!--                                            <p class="" v-html="i.area">-->
                        <!--                                                Community schools in rural villages-->
                        <!--                                            </p>-->
                        <!--                                        </div>-->

                        <!--                                    </div>-->
                        <!--                                </swiper-slide>-->

                        <!--                            </swiper>-->
                        <!--                        </div>-->
                    </div>
                </div>

            </div>

        </section>

        <section class="change-section-bg text-white py-5">
            <div class="container">
                <div
                    class="row">
                    <div class="col-lg-6"></div>
                    <div class="col-lg-6">
                        <h2 class="text-left font-weight-bold mt-3 mb-5 text-white">You are The Change</h2>
                        <p class="proxim-light c-padding-t-20 c-padding-b-sm-20 c-padding-b-xs-15 c-font-sm-14 c-font-xs-12 text-justify">
                            Your involvement and contribution of time,effort and funds are the catalyst
                            that have helped us to do so much than we could have alone .Whether you are a volunteer,
                            donor,
                            sponser, approving authority or a partner, you have a hand lvn transforming
                            someone's life somewhere</p>

                        <div class="about-buttons row">
                            <div
                                class="col-12 col-xl-4 col-6 padd-bottom-md mb-lg-0 mr-lg-4">
                                <!--                                <router-link to="/partnerus/" class="btn btn-block btn-primary">-->
                                <!--                                    Partner With us-->
                                <!--                                </router-link>-->
                                <div class="text-center">
                                    <btn border-radius="0" @click="$router.push({path: '/partner-with-us/'})"
                                         class="py-3 px-5 mb-3 mb-lg-0 lego-btn btn-basic btn-color-primary bs-1 bs-1-h red-l-gradient d-inline-block">
                                        Partner With us
                                    </btn>
                                </div>

                            </div>

                            <div
                                class="col-12 col-xl-4 col-6 padd-bottom-md mb-lg-0">
                                <!--                                <router-link to="/partnerus/" class="btn btn-block btn-white">-->
                                <!--                                    Join With Us-->
                                <!--                                </router-link>-->
                                <div class="text-center">

                                    <router-link to="/work-with-us/"
                                                 class="lego-btn px-5 mb-3 mb-lg-0 round-1 bs-10 bg-white  bs-1-h btn-lg line-h-unset border-r-0 fs-1 flex-c btn-white">
                                        Apply Now
                                    </router-link>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </section>

    </div>

</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'OurFocus',

    components: {
        Swiper,
        SwiperSlide
    },

    data () {
        return {
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 0,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: false,
                centeredSlides: true,
                // autoplay: false,
                autoplay: {
                    delay: 4500,
                    disableOnInteraction: false
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    }
                }
            },
            swiperOption4: {
                slidesPerView: 1,
                spaceBetween: 0,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: false,
                // autoplay: false,
                autoplay: {
                    delay: 4500,
                    disableOnInteraction: false
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 2,
                        spaceBetween: 0
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    }
                }
            },
            swiperOption5: {
                slidesPerView: 1,
                // loop: true,
                navigation: false,
                centeredSlides: true,
                autoplay: false,
                // autoplay: {
                //     delay: 4000,
                //     disableOnInteraction: false
                // },
                breakpoints: {
                    1024: {
                        slidesPerView: 2,
                        spaceBetween: 30
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 30
                    },
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    }
                }
            },
            details: null,
            loading: false,
            error: false
        };
    },

    mounted () {
        this.loadData();
    },

    methods: {
        loadData () {
            const that = this;
            that.loading = true;
            that.error = false;
            axios.form(urls.admin.program.details, { id: that.$route.params.id }).then(function (response) {
                const json = response.data;
                that.details = { ...json.data };
                that.loading = false;
            }).catch(function (exception) {
                that.loading = false;
                that.error = true;
                console.log('exception : => ', exception);
            });
        }
    }
};
</script>

<style scoped lang="scss">
.section-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(1, 56, 124, .46);
    z-index: 0;
    cursor: pointer;
}

.focus-banner {
    height: 400px;
}

.bg-focus-area {

    background-image: url(../../assets/img/programmes/focus-area-bg-2.png);
    background-repeat: no-repeat;
    background-size: cover;
    //background-position: left;
    background-position: 11% 0%;
    color: #fafafa !important;

    @media (min-width: 992px) {

        background-image: url(../../assets/img/programmes/focus-area-bg-2.png);
        background-position: left;
        color: #323232 !important;

    }
}

.change-section-bg {
    background-image: url(../../assets/img/bg/partner-with-us.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.focus-p-pos-rel {
    position: relative;
}

.c-padding-b-md-10 {
    @media (min-width: 992px) {
        padding-bottom: 10px !important;
    }
}

.focus-p-pos-abs {
    position: absolute;
    width: 5px;
    height: 5px;
    border: 1px solid #000;
    border-radius: 50%;
    box-sizing: border-box;
    word-break: break-all;
    top: 8px;
    left: -15px;
}

.p-left {
    left: 10%;
}

.p-left-50 {
    left: 45%;
}

.underline-pos-abs {
    width: 10%;
    background: #cc3b29;
    height: 6px;
    position: absolute;
    right: 45%;
}
</style>
