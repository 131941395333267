<template>
    <div class="py-7 bg-contact m-0">
        <div class="container text-center">
            <h2 class="mb-5 font-weight-bold">Message Us</h2>
            <div class="row mb-5">
                <div class="col-12 contact-form">

                    <transition name="fade" mode="out-in">
                        <div class="text-center p-4 col-lg-10" v-if="state === 'S'" key="'S">
                            <div class="text-success">
                                <div class="text-center mb-4">
                                    <i class="fa fa-check-square-o  fa-3x" aria-hidden="true"></i>
                                </div>
                                <div class="text-center">
                                    <p class="fs-4">Thank you <strong>{{ name }}</strong> for contacting us</p>
                                    We have received your enquiry and will respond to you within 24 hours.
                                </div>
                            </div>
                        </div>

                        <div class="text-center p-4 col-lg-10" v-else-if="state === 'F'" key="'F">
                            <div class="text-danger">
                                <div class="text-center mb-4">
                                    <i class="fa fa-window-close  fa-3x" aria-hidden="true"></i>
                                </div>
                                <div class="text-center">
                                    <p class="fs-4"><strong>OOPS!</strong></p>
                                    Something has gone wrong, Please try again.
                                </div>
                            </div>
                        </div>

                        <div class="alert alert-info" v-else-if="state === 'L'" key="'L">
                            <div class="fl-x fl-x-cc">
                                <div class="text-center p-4">
                                    <loading-animation></loading-animation>
                                    <p class="c-font-19 mb-4 proxim-light">Please wait while communicate with the
                                        server</p>
                                </div>
                            </div>
                        </div>

                        <!--                        //Failure-->
                        <!--                        //Loading-->
                        <b-form class="" :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">
<!--                            <s-form key="'form" @submit="onSubmit">-->
                                <div class="row ext-center">
                                    <div class="col-12 col-lg-4">
                                        <validated-input placeholder="Enter Name" :border-radius="0"
                                                         v-model="model.name"
                                                         :rules="rules.name1" :disabled="loading"
                                                         class="form-m-b-1"></validated-input>
                                    </div>
                                    <div class="col-12 col-lg-4">
                                        <validated-input type="email" v-model="model.email" :rules="rules.email_id"
                                                         placeholder="Email ID" :border-radius="0"
                                                         :disabled="loading" class="form-m-b-1"></validated-input>
                                    </div>
                                    <div class="col-12 col-lg-4">
                                        <validated-input placeholder="Phone Number" :border-radius="0"
                                                         v-model="model.phone_number"
                                                         :rules="rules.phone_number" :disabled="loading"
                                                         class="form-m-b-1"></validated-input>
                                    </div>
                                    <div class="col-12">
                                        <validated-text-area placeholder="Enter Message" class="text-area-md form-m-b-1"
                                                             v-model="model.message"
                                                             :border-radius="0"
                                                             :disabled="loading"></validated-text-area>
                                    </div>
                                    <div class="text-center fl-x fl-j-c w-100">
                                        <!--                        <btn text="Sumbit" class="px-5" size="lg" type="submit"></btn>-->
                                        <btn border-radius="0" :loading="loading" loading-text="Saving..."
                                             class="py-3 px-5 lego-btn btn-basic btn-color-primary bs-1 bs-1-h red-l-gradient d-inline-block">
                                            Submit
                                        </btn>
                                    </div>
                                </div>
                            </b-form>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';
import LoadingAnimation from '@/views/components/LoadingAnimation';

export default {
    name: 'MessageUsFormNew',
    components: {
        LoadingAnimation
    },
    data () {
        return {
            loading: false,
            state: 'D',
            model: {
                name: '',
                email: '',
                city: '',
                phone_number: '',
                message: ''
            },
            addUrl: urls.admin.ContactUs.add,
            rules: {
                name1: {
                    required: true
                },
                email_id: {
                    required: true
                },
                phone_number: {
                    required: true
                },
                city1: {
                    required: true
                },
                message1: {
                    required: true
                }
            }
        };
    },
    methods: {
        async submit () {
            // alert('submit');
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.admin.ContactUs.email, that.model);
            if (response.data.success) {
                that.loading = false;
                that.$notify('Enquiry submitted successfully', 'Success', {
                    type: 'success'
                });
                that.loading = false;
            } else {
                that.$notify('Something went wrong', 'Error', {
                    type: 'danger'
                });
                that.loading = false;
            }
        },
        onSubmit () {
            // alert('submit');
            const bodyFormData = new FormData();
            bodyFormData.append('name', this.name);
            bodyFormData.append('email', this.email);
            bodyFormData.append('phone', this.phone);
            // bodyFormData.append('city', this.city);
            bodyFormData.append('message', this.message);
            console.log('form-data', bodyFormData);
            const that = this;
            this.state = 'L';

            axios({
                method: 'post',
                url: 'https://esafglobalcanada.xeoscript.com/mail.php',
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(function (response) {
                that.onSubmitSuccess(response);
            }).catch(function (response) {
                that.onSubmitFailed(response);
            });
        },

        onSubmitSuccess () {
            this.state = 'S';
        },

        onSubmitFailed () {
            this.state = 'F';
        },

        formSuccess () {
            alert('Success');
            location.reload();
        }
    }

};
</script>

<style scoped lang="scss">
.bg-contact {
    background-image: url("../../../assets/img/bg/bg-07.png");
    background-position: bottom;
}
</style>
