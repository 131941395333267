<template>
    <div class="p-4 text-center">
        <h2 class="mb-5">{{ titleName }}</h2>
<!--        <s-form class="" @submit="onSubmit">-->
        <s-form class="">
            <div class="row text-center">
<!--                <div class="col-12 col-lg-6">-->
<!--                    &lt;!&ndash;                        <validated-input placeholder="Enter Name" :border-radius="0"></validated-input>&ndash;&gt;-->
<!--                    <validated-vue-select placeholder="Select A Project" :options="options" @input="setAmount"-->
<!--                                               :border-radius="0" v-model="model.project"></validated-vue-select>-->
<!--                </div>-->

<!--                <div class="col-12 col-lg-6">-->
<!--                    <validated-select placeholder="No of Units" v-model="model.units"-->
<!--                                      :options="units"></validated-select>-->
<!--                </div>-->
<!--                <div class="col-12 col-lg-6">-->
<!--                    <validated-input type="number" placeholder="Amount" v-model="model.amount"-->
<!--                                     :border-radius="0" disabled></validated-input>-->
<!--                </div>-->
                <div class="text-center fl-x fl-j-c w-100">
                    <!--                    <btn text="Continue" type="submit" class="py-3 px-5" design="basic-a" border-radius="0"/>-->
<!--                    <btn border-radius="0" type="submit"-->
<!--                         class="py-3 px-5 lego-btn btn-basic btn-color-primary bs-1 bs-1-h red-l-gradient d-inline-block">-->
<!--                        Continue-->
<!--                    </btn>-->
                    <a class="xeo-btn" href="https://www.canadahelps.org/CharityProfilePage.aspx?CharityID=d119640">
                                <img src="https://www.canadahelps.org/secure/img/Donate1_en.png" border="0"
                                     alt="Donate Now Through CanadaHelps.org!"/></a>
                </div>
            </div>
        </s-form>

        <modal ref="paymentModal" class="c-modal"
               title="Payment">
            <payment-modal title-name="Payment" @close="$emit('close')"></payment-modal>
        </modal>
    </div>
</template>

<script>
import urls from '@/data/urls';
import PaymentModal from '@/views/components/Donate/PaymentModal';
import axios from 'secure-axios';

export default {
    name: 'AdoptAProject',

    components: { PaymentModal },

    props: {
        titleName: { type: String },
        type: { type: String }
    },

    data () {
        return {
            optionURL: urls.admin.project.options + '?type=' + this.type,
            options: urls.admin.project.options + '?type=' + this.type,
            timeFrameOptions: [
                { label: 'Give Once', value: 'once' },
                { label: 'Monthly', value: 'monthly' }
            ],
            units: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            model: {
                project: '',
                time_frame: '',
                name: '',
                email: '',
                phone_number: '',
                amount: '',
                units: ''
            }
        };
    },

    mounted () {
        this.loadAllOptions();
    },

    methods: {
        onSubmit () {
            // alert('Coming soon..!');
            this.openPaymentModal();
            // close current modal
            // setTimeout(() => {
            //     this.$emit('close');
            // }, 1000);
        },
        openPaymentModal () {
            this.$refs.paymentModal.show();
        },

        loadAllOptions () {
            const that = this;
            axios.get(this.optionURL).then(function (response) {
                const allOptions = response.data;
                that.options = allOptions.data;
            });
        },

        setAmount () {
            const foundValue = this.options.filter(obj => obj.value === this.model.project);
            this.model.amount = foundValue[0].amount;
        }

    }
};
</script>

<style scoped lang="scss">
.box-donate {
    background-color: #fff;
    border: 1px solid var(--color-primary);
    padding: 1.5rem;
    //height: 100%;
    height: 2.9375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        background-color: #dedede;
    }
}
</style>
