<template>
    <div class="py-5">
        <div class="container text-center">
            <div class="card">
                <h2 class="mb-5">Volunteer With Us</h2>
                <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">
                    <div class="row ext-center">
                        <div class="col-12 col-lg-4">
                            <validated-input placeholder="Enter Name" v-model="model.name" :disabled="loading" :border-radius="0"></validated-input>
                        </div>
                        <div class="col-12 col-lg-4">
                            <validated-input type="number" v-model="model.age" placeholder="Age" :disabled="loading" :border-radius="0"></validated-input>
                        </div>
                        <div class="col-12 col-lg-4">
                            <validated-select placeholder="Select Gender" :options="genderOptions"
                                              :border-radius="0" v-model="model.gender" :disabled="loading"></validated-select>
                        </div>
                        <div class="col-12 col-lg-4">
                            <validated-input type="email" placeholder="Email ID" v-model="model.email" :disabled="loading" :border-radius="0"></validated-input>
                        </div>
                        <div class="col-12 col-lg-4">
                            <validated-input type="phone" placeholder="Phone Number"
                                             :border-radius="0" v-model="model.phone_number" :disabled="loading"></validated-input>
                        </div>
                        <div class="col-12">
                            <validated-text-area class="text-area-md" placeholder="Enter Message"
                                                 :border-radius="0" v-model="model.message" :disabled="loading"></validated-text-area>
                        </div>
                        <div class="text-center fl-x fl-j-c w-100">
                            <!--                            <btn text="Apply Now" class="px-5" size="lg" type="submit"></btn>-->
                            <btn border-radius="0" type="submit" text="Apply Now" :loading="loading" loading-text="Saving..."
                                 class="py-3 px-5 lego-btn btn-basic btn-color-primary bs-1 bs-1-h red-l-gradient d-inline-block">
                            </btn>
                        </div>
                    </div>
                </b-form>
            </div>
        </div>
    </div>
</template>

<script>
import urls from '@/data/urls';

export default {
    name: 'VolunteerWithUs',
    data () {
        return {
            genderOptions: [
                {
                    label: 'Male',
                    value: 'm'
                },
                {
                    label: 'Female',
                    value: 'f'
                },
                {
                    label: 'Other',
                    value: 'oth'
                }
            ],
            model: {
                name: '',
                age: '',
                gender: '',
                email: '',
                phone_number: '',
                message: ''
            },
            addUrl: urls.admin.volunteerWithUs.add
        };
    },

    methods: {
        formSuccess () {
            alert('Success');
            location.reload();
        }
    }
};
</script>

<style scoped>

</style>
