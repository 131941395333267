<template>
    <section class="bg-4 text-white py-7">
        <img src="../../../assets/img/layers/layer-01.png" alt="" class="layout-bg-flow">
        <img src="../../../assets/img/layers/layer-07.png" alt="" class="layout-bg-flow-right">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6">
                    The story of ESAF is akin to the growth of a mustard seed. A humble beginning that
                    flourishes to be impactful and provides shelter to many. The seeds were sown in the
                    early 1990s. Burning issues of the day like unemployment of the educated youth and abject
                    poverty in the rural households stirred Mr. Paul Thomas, his wife Mrs. Mereena
                    Paul, and Dr. Jacob Samuel - who were members of the Kerala Evangelical Graduates’
                    Fellowship (EGF) - to think radically about the relevance of their faith in addressing
                    the social issues around them. Based on the conviction that the command of Jesus Christ,
                    “Love your neighbor as yourself’’ has a social dimension, they brainstormed for
                    several months on how to translate their conviction into action. Their mustard seed-like
                    faith put into action resulted in the formation of the Evangelical Social Action Forum
                    (ESAF) in 1992 to serve the poor and the marginalized and create opportunities for their
                    holistic development.
                </div>
                <div class="col-12 col-lg-6">
                    <div class="fl-x fl-a-c fl-j-c iframe-contain mb-3">

                        <!--                        <iframe class="w-100" src="https://www.youtube.com/embed/05w3CYxxpeg"-->
                        <!--                                title="YouTube video player" frameborder="0"-->
                        <!--                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
                        <!--                                allowfullscreen></iframe>-->
<!--                        <iframe class="w-100 h-100" src="https://www.youtube.com/embed/Cl-t4gYAeZE"-->
                        <!--                                title="YouTube video player" frameborder="0"-->
                        <!--                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
                        <!--                                allowfullscreen></iframe>                        -->
                        <iframe class="w-100 h-100" src="https://www.youtube.com/embed/81rnQReP89g"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                    </div>

                </div>
                <div class="col-12">
                    <p class="py-3">
                        ESAF's humble beginning is best captured from the house where it started its operations 'Little'
                        - the
                        rented house of Mr Paul Thomas & family. ESAF started its operations by organising neighbourhood
                        woman self-help
                        groups(SHGs) as early as 1994, and other community-led development activities in kerala's
                        Thrissur district.
                        The commitment towards the poor and underprivileged made ESAF expand its operations to several
                        remote districts of
                        Maharashtra, Jharkhand, Chhattisgarh, and Tamil Nadu in the initial years itself.
                    </p>
                    <p>
                        Career guidance for youths, providing micro-credit and micro-enterprise promotions to SHGs,
                        community-based health interventions including mental health, counseling,
                        home-based care and livelihood interventions for people living with HIV /AIDS, disaster
                        response, etc., were some of ESAF's earlier areas of intervention. Over the years,
                        ESAF has partnered with several government agencies, national and international
                        organizations, and has received several national and international recognition. ESAF's
                        social-business model helped start 18 social enterprises in various sectors and the mustard
                        tree is impacting more than 3.5 million lives across 16 states in India.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="bg-white text-left pt-5 pb-3 px-4">
                        <h2 class="mb-3 text-center font-weight-bold">Esaf Global Canada</h2>
                        <p class="text-left">
                            ESAF GLOBAL - CANADA has the benefit of being an offshoot of the Evangelical Social Action
                            Forum (ESAF) which was started as an NGO in India in 1992. ESAF GLOBAL -
                            CANADA will be leveraging the time-tested success of 30 years that ESAF has had in India.
                            ESAF, a national-level social development organization in India was established
                            by a group of professionals with a vision to facilitate the transformation of several
                            disadvantaged areas of the society by enabling them to seek opportunities for growth
                            and development. ESAF began its community-led initiatives and development activities in
                            Thrissur district of Kerala which soon spread across several remote districts in
                            Maharashtra, Jharkhand, Chhattisgarh, Tamil Nadu, and beyond. A dedicated group of
                            individuals within ESAF have successfully provided interventions in microfinance,
                            water and sanitation, natural resources management, livelihood promotion, child development,
                            and advocacy.
                        </p>
                        <!--                        <p>-->
                        <!--                            The story of ESAF is akin to the growth of a mustard seed. A humble beginning that-->
                        <!--                            flourishes to be impactful and provides shelter to many. The seeds were sown in the-->
                        <!--                            early 1990s. Burning issues of the day like unemployment of the educated youth and abject-->
                        <!--                            poverty in the rural households stirred Mr. Paul Thomas, his wife Mrs. Mereena-->
                        <!--                            Paul, and Dr. Jacob Samuel - who were members of the Kerala Evangelical Graduates’-->
                        <!--                            Fellowship (EGF) - to think radically about the relevance of their faith in addressing-->
                        <!--                            the social issues around them. Based on the conviction that the command of Jesus Christ,-->
                        <!--                            “Love your neighbor as yourself’’ has a social dimension, they brainstormed for-->
                        <!--                            several months on how to translate their conviction into action. Their mustard seed-like-->
                        <!--                            faith put into action resulted in the formation of the Evangelical Social Action Forum-->
                        <!--                            (ESAF) in 1992 to serve the poor and the marginalized and create opportunities for their-->
                        <!--                            holistic development.-->
                        <!--                        </p>-->

                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'OurHistoryBanner'

};
</script>

<style lang="scss" scoped>
.iframe-contain {
    height: 16.25rem;
    @media (min-width: 992px) {
        height: 100%;
    }
}

section {
    position: relative;
}

.layout-bg-flow {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    @media (min-width: 992px) {
        width: 20%;
    }
}

.layout-bg-flow-right {
    position: absolute;
    right: 0;
    top: 25%;
    bottom: 0;
    z-index: 0;
    @media (min-width: 992px) {
        width: 15%;
    }
}
</style>
