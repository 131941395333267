<template>
    <div class="p-4">
        <h2 class="mb-5">{{ titleName }}</h2>
        <s-form class="" @submit="onSubmit">
            <div class="row text-center">
                <div class="col-12 col-lg-6">
                    <validated-input placeholder="Name" v-model="model.name" :rules="rules.name" :border-radius="0" class="form-m-b-1"></validated-input>

                </div>

                <div class="col-12 col-lg-6">
                    <validated-input type="email" placeholder="Email ID" :rules="rules.email" v-model="model.email"
                                     :border-radius="0" class="form-m-b-1"></validated-input>

                </div>
                <div class="col-12 col-lg-6">
                    <validated-input type="phone" placeholder="Contact Number" v-model="model.phone_number"
                                     :border-radius="0"></validated-input>

                </div>
                <div class="col-12 col-lg-6">
                    <validated-input placeholder="Institution Name" v-model="model.inst_name"
                                     :border-radius="0"></validated-input>
                </div>
                <div class="col-12 col-lg-6">
                    <validated-input placeholder="Institution Address" v-model="model.inst_addr"
                                     :border-radius="0"></validated-input>
                </div>

                <div class="text-center fl-x fl-j-c w-100">
                    <btn border-radius="0"
                         class="py-3 px-5 lego-btn btn-basic btn-color-primary bs-1 bs-1-h red-l-gradient d-inline-block">
                        Pay Now
                    </btn>
                </div>
            </div>
        </s-form>
    </div>
</template>

<script>
export default {
    name: 'PaymentModal',
    props: {
        titleName: { type: String }
    },
    data () {
        return {
            model: {
                name: '',
                email: '',
                phone_number: '',
                inst_name: '',
                inst_addr: ''
            },
            rules: {
                name: {
                    required: true
                },
                email: {
                    required: true
                }

            }
        };
    },
    methods: {
        onSubmit () {
            // alert('Coming soon..!');
            this.$emit('close');
        }
    }
};
</script>

<style scoped>

</style>
