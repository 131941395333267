<template>
    <section class="m-0">
        <!--        <iframe class="w-100 map-h"-->
        <!--            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9495.742058868083!2d-2.323895!3d53.487482!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf431c32fbf1abb0d!2sSalford%20Royal%20NHS%20Foundation%20Trust!5e0!3m2!1sen!2sin!4v1635766992743!5m2!1sen!2sin"-->
        <!--             style="border:0;" allowfullscreen="" loading="lazy"></iframe>-->
        <iframe class="w-100 map-h"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20018.239380335996!2d-114.1616492758017!3d51.15862859053089!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5371687e698571e7%3A0xdc74bfc6b32dd1d3!2s11680%20Sarcee%20Trail%20NW%2C%20Calgary%2C%20AB%20T3R%200A1%2C%20Canada!5e0!3m2!1sen!2sin!4v1640341204808!5m2!1sen!2sin"
            style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </section>
</template>

<script>
export default {
    name: 'MapComponent'
};
</script>

<style scoped>
.map-h {
    height: 25.0625rem;
}
</style>
