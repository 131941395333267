<template>
    <section class="py-5 bg-3 pos-r full-height">
        <img src="../assets/img/layers/layer-06.png" alt="#" class="z-index-0 layout-bg left">
        <div class="container z-index-4">
            <h2 class="text-center font-weight-bold mb-3">Our Members</h2>
            <person-info-card type="board" class="z-index-2" :name="currentMember.name" size="lg" :img="currentMember.img"
                              :designation="currentMember.design">
                <template #details>
                    <p class="text-center  p-3 font-weight-light">
                        {{ currentMember.info }}
                    </p>
                </template>
            </person-info-card>
        </div>
    </section>
</template>

<script>
import PersonInfoCard from '@/views/components/UI/PersonInfoCard';
// import sampleImg from '@assets/img/board/0.jpg';
import personImg1 from '@assets/img/board/04.jpg';
import personImg2 from '@assets/img/board/06.jpg';
import personImg3 from '@assets/img/board/07.jpg';
import personImg4 from '@assets/img/board/eldon-gamble-chairman.jpg';
import personImg5 from '@assets/img/board/011.jpg';
import placeholderImg from '@assets/img/placeholder/placeholder.png';
export default {
    name: 'BoardMemberDetailsPage',
    components: { PersonInfoCard },
    props: {
        name: {
            type: String,
            default: 'Name'
        },
        designation: {
            type: String,
            default: 'Designation'
        },
        info: {
            type: String,
            default: 'info about the person'
        },
        img: {
            type: Image,
            default: personImg1
        },
        id: {
            type: Number,
            default: 0
        }
    },
    mounted () {
        console.log('id of founder', this.$route.params.name);
        const memberName = this.$route.params.name.replace(/\s+/g, '');
        for (let i = 0; i < this.members.length; i++) {
            const currMember = Object.assign({}, this.members[i]);
            const currName = currMember.name;
            if (currName.replace(/\s+/g, '') === memberName) {
                this.currentMember = currMember;
            }
        }
        // this.currentMember = this.members.find(item => item.name.replace(/\s+/g, '') === memberName);
        // console.log('current member', this.currentMember);
    },
    data () {
        return {
            currentMember: null,
            members: [
                {
                    name: 'J. ELDON GAMBLE - CANADA',
                    desig: 'Board Chairman',
                    img: personImg4,
                    info: 'Eldon is an Aircraft Maintenance Engineer holding a BSc. Degree in Mechanical Engineering Technology and a Diploma in Aircraft Maintenance Technology from\n' +
                        'LeTourneau University, Longview, Texas, USA. Eldon also completed his A&P (Airframe & Power plant) certification. Later in his career received his Canadian Aircraft\n' +
                        'Maintenance Engineers (AME) license and earned the Boeing 727 Endorsement, DC-9 Endorsement, and the Airbus A320 Endorsement. Eldon was born in Canada, at a\n' +
                        'very young age went to India with his parents, and did his schooling there until graduation. He grew up speaking many Indian languages that include Hindi, Bengali, and\n' +
                        'Santali. Eldon started his career as an aircraft mechanic for the field of Aviation in Calgary worked in Artic for Atlas Aviation and several years later as a Maintenance\n' +
                        'Engineer in Air Canada for a few years. He has spent 15 years in Bangladesh working as a mechanic and was involved in skill training for underprivileged Bangladeshi\n' +
                        'young men, before working in Somalia and Kenya in the aviation field. In Kenya, Eldon served as the first chief engineer for (AIM) Africa Island Mission operating aircraft\n' +
                        'that were serving Kenya, Tanzania, Uganda, Sudan, and Zaire. His passion is to see those from underprivileged and marginalized peoples obtain skills to further their lives\n' +
                        'and impact the whole of local society. At present Eldon is actively involved in helping the immigrant communities of Calgary from South Asia.'
                },
                {
                    name: 'BEENA GEORGE - INDIA',
                    desig: 'Chief People Officer, ESAF, Co-operative',
                    img: personImg5,
                    info: 'Beena is a development professional with 20 + years of experience in various sectors, including, program development and management, monitoring and evaluation,\n' +
                        'human resource management, research, and advocacy, etc. Beena has completed M.Phil. in Psychiatric Social Work from National Institute of Mental Health and Neuro\n' +
                        'Sciences, NIMHANS, Bangalore, India, and a Master’s degree in Social Work, Bishop Heber College, Tiruchirappalli, India after her under graduation in Botany. She worked\n' +
                        'as a director-corporate service in ESAF India, for the last two decades in consulting with domestic and international organizations. She is skilled in multiple language\n' +
                        'proficiency, with multi-cultural development work exposure across several Indian states. At present working as the chief people officer at the Co-operative organization of\n' +
                        '\n' +
                        'ESAF groups.'
                },
                {
                    name: 'ALLAN WILLMS - CANADA',
                    desig: 'Treasurer',
                    img: placeholderImg,
                    info: 'Allan graduated with honors from the University of Waterloo with a Bachelor of Science in Kinesiology. He worked in the automotive industry for 15 years where he held\n' +
                        'positions in Engineering, Production, Supply chain management, and Sales. Allan and his family spent 2 years in China developing international supply chains to service\n' +
                        'large OEM automotive customers in North America. Allan then transitioned into the fuel and lubricant business in Western Canada where he has worked for the past +10\n' +
                        'years. He is currently working for a Group of Companies as the Vice President and Chief Operating Officer. Allan was on the board of elders and served as the Treasurer at\n' +
                        'Rock Pointe Church, Calgary for the last four years. He is also on a for-profit board of a private business in the sports and athletic development field. Allan has been on\n' +
                        'trips to Guatemala to build homes for the homeless and the disadvantaged as well as the construction of an alcohol rehabilitation Centre.'
                },
                {
                    name: 'DR. AJITHSEN SELVADHAS - INDIA',
                    desig: 'Vice President: Cedar Retail Pvt Ltd & Associate Director: ESAF Foundation',
                    img: personImg3,
                    info: 'Ajithsen is a designer and artist, who has dedicated his skills and talents to the upliftment of the poor and marginalized communities in India. He has graduated in\n' +
                        'Industrial design in ceramics with distinction &amp; the best student award from Chennai, India. He is a gold medalist in his Master’s degree in Pottery &amp; Ceramics, from\n' +
                        'Banaras Hindu University, Varanasi India, and acquired Ph.D. in the “ Role of Design in the Economic Scaling up of Tribal arts &amp; Crafts” from Martin Luther Christian\n' +
                        'University, Shillong, India. The former years of his career were in the field of design &amp; technology in Ceramics, then moved to the development sector with the ESAF group\n' +
                        'in various positions, that includes in education, microcredit, enterprise development, and skill training initiatives. Ajithsen is a member of the “world fair trade\n' +
                        'organization” and “Indian fair trade forum”. He presented papers in the competency building program, in Thailand representing ESAF. At present he is working with the\n' +
                        'indigenous communities of Eastern India for the last 13 years, Initiated an integrated holistic, development program improving their livelihood, skills, entrepreneurship,\n' +
                        '\n' +
                        'along with designing thousands of eco-friendly lifestyle product ranges and sales channels in India and Europe, creating sustainable impact in those communities.'
                },
                {
                    name: 'SAJI GEORGE - CANADA',
                    desig: 'Project Director',
                    img: personImg2,
                    info: 'Saji is a professional engineer holding a Masters of Engineering Degree and obtained Professional Engineer (P.ENG) and Project Management Professional (PMP)\n' +
                        'certification from Canada. He started his career as a University faculty for Engineering for 5 years and worked more than two decades in the energy industry in various\n' +
                        'capacities in different countries. Saji has managed mega projects of multinational corporations globally. He has experience being on the board of both non-profit and\n' +
                        'profit organizations. His passion is to see the marginalized youth and their families be uplifted to self-sufficiency by vocational skill training that is required in the\n' +
                        'marketplace and to create opportunities for their livelihood so to see them thrive. He is also passionate about supporting the holistic approach of community\n' +
                        'development among the poor and needy.'
                },
                {
                    name: 'BIJILA G. - CANADA',
                    desig: 'Chief Executive Officer',
                    img: personImg1,
                    info: 'Bijila has an academic background of a double major in Science and Library and Information Science. She obtained a Master’s degree (MLIS) from the University of\n' +
                        'Madras, India. She has done Applied suicide intervention skills training from the Canadian Mental Health Association (ASSIST 11). She worked in various roles in Libraries\n' +
                        'in India and the UAE before working as an entrepreneur, being the managing director of a wholesale business and retail business. She obtained the Best Librarian award,\n' +
                        'and appreciation awards from the UAE Nations’ First Lady’s office. She received special awards for her contributions to the school for integrating international\n' +
                        'accreditation standards. She is a writer, published author, speaker at youth conferences. Her many years of volunteer services include working at the crisis line in Distress\n' +
                        'Centre, Inn From Cold, Samaritan Purse flood rehabilitation efforts in Calgary, etc. Bijila is passionate about helping the helpless and marginalized in societies and is\n' +
                        'actively engaged in various roles in social justice and community development projects.'
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
