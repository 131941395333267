<template>
    <div class="fl-y fl-a-c px-4 py-7 h-100" :style="{'background-image':'url('+ bgImage +')'}">
        <div class="fl-x fl-j-c fl-a-c img-c mb-5">
            <img :src="topImg" alt="" class="img-fluid w-40">
        </div>
        <h2 class="mb-4" v-if="heading !== ''">{{ heading }}</h2>
        <div class="mb-4" v-else>
            <slot name="heading"></slot>
        </div>
        <p class="text-center mb-4 px-3 text-black" v-if="content !== ''">
            {{
                content
            }}
        </p>
        <div class="text-center mb-4 px-3 text-black m-h-9" v-else>
            <slot name="content" class="text-center mb-5"></slot>
        </div>
        <footer class="">
            <slot name="footer"></slot>
        </footer>
    </div>
</template>

<script>
import bg1 from '../../../assets/img/bg/bg-01.png';

export default {
    name: 'ShowCard',
    props: {
        bgImage: {
            type: Image
        },
        topImg: {
            type: Image
        },
        heading: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            bgImg1: bg1
        };
    }
};
</script>

<style scoped>
    .img-c{
        min-height: 6rem;
    }
    .m-h-5{
        min-height: 5rem;
    }
</style>
