<template>
    <div class="bg-white">
        <section>
            <contact-us-banner></contact-us-banner>

        </section>
        <section>
            <map-component></map-component>

        </section>
        <!--        <message-us-form></message-us-form>-->
        <section id="MessageUsForm" class="">
            <message-us-form-new></message-us-form-new>
        </section>
    </div>
</template>

<script>
import ContactUsBanner from '@/views/components/ContactUs/ContactUsBanner';
import MapComponent from '@/views/components/ContactUs/MapComponent';
// import MessageUsForm from '@/views/components/ContactUs/MessageUsForm';
import MessageUsFormNew from '@/views/components/ContactUs/MessageUsFormNew';

export default {
    name: 'ContactUs',
    components: { MessageUsFormNew, MapComponent, ContactUsBanner }
};
</script>

<style scoped lang="scss">
//.content {
//    margin-top: 0;
//    float: left;
//    width: 85%;
//}
</style>
