<template>
    <section class="py-5 bg-3">
        <div class="container">
            <h2 class="font-weight-semibold text-center mb-4">ESAF INDIA IMPACT - MAP</h2>
            <div class="row">
                <div class="col-12 col-lg-7">
                    <div class="row">
                        <icon-card class="col-12 col-lg-6 mb-2" v-for="(item,i) in items" :key="i"
                                   :content="item.desc">
                            <template #image>
                                <div class="fl-x fl-a-c">
                                    <img :src="item.img" alt="" class="img-fluid mr-4">
                                </div>
                            </template>
                            <template #heading>
                                <div class="fl-x fl-a-c">
                                    <p class="text-1x font-weight-bold m-0">{{ item.heading }}</p>
                                </div>
                            </template>
                        </icon-card>
                    </div>
                </div>
                <div class="col-12 col-lg-5">
                    <img src="../../../assets/img/maps/map-01.png" alt="" class="img-fluid">
                </div>
            </div>

        </div>
    </section>
</template>

<script>
import IconCard from '@/views/components/UI/IconCard';
import icon1 from '../../../assets/img/icons/icon-1.png';
import icon2 from '../../../assets/img/icons/icon-2.png';
import icon3 from '../../../assets/img/icons/icon-3.png';
import icon4 from '../../../assets/img/icons/icon-4.png';
import icon5 from '../../../assets/img/icons/icon-5.png';
import icon6 from '../../../assets/img/icons/icon-6.png';
import icon7 from '../../../assets/img/icons/icon-7.png';
import icon8 from '../../../assets/img/icons/icon-8.png';
import icon9 from '../../../assets/img/icons/icon-9.png';
import icon10 from '../../../assets/img/icons/icon-10.png';
import icon11 from '../../../assets/img/icons/icon-11.png';
import icon12 from '../../../assets/img/icons/icon-12.png';
export default {
    name: 'ImpactMap',
    components: { IconCard },
    data () {
        return {
            items: [
                {
                    img: icon1,
                    heading: '29',
                    desc: 'Projects Undertaken'
                },
                {
                    img: icon2,
                    heading: '10',
                    desc: 'States influence'
                },
                {
                    img: icon3,
                    heading: '557,000',
                    desc: 'Directly Benefited'
                },
                {
                    img: icon4,
                    heading: '336,000',
                    desc: 'People directly reached through flood relief & rehabilitation'
                },
                {
                    img: icon5,
                    heading: '55,705',
                    desc: 'Acres of land used for agriculture'
                },
                {
                    img: icon6,
                    heading: '38,384+',
                    desc: 'Farmers Empowered'
                },
                {
                    img: icon7,
                    heading: '8,693KG',
                    desc: 'of Agri produce from 21 schools'
                },
                {
                    img: icon8,
                    heading: '4,760+',
                    desc: 'Rural women receive health awareness'
                },
                {
                    img: icon9,
                    heading: '4,873',
                    desc: 'Underprivileged youth and children get skill training and education'
                },
                {
                    img: icon10,
                    heading: '2,127+',
                    desc: 'Indigenous people get better livelihood opportunities'
                },
                {
                    img: icon11,
                    heading: '312,000',
                    desc: 'Reduced tonnes of carbon emissions'
                },
                {
                    img: icon12,
                    heading: '37',
                    desc: 'Public Spaces made inclusive'
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
